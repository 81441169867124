export const ACTIVATE_ESTIMATION = 'ACTIVATE_ESTIMATION'
export const AGGREGATION_BY_COLUMN_GROUP = 'AGGREGATION_BY_COLUMN_GROUP'
export const AGGREGATION_CREATE = 'AGGREGATION_CREATE'
export const AGGREGATION_DELETE = 'AGGREGATION_DELETE'
export const AGGREGATION_UPDATE = 'AGGREGATION_UPDATE'
export const APP_DATA_PERMISSIONS = 'APP_DATA_PERMISSIONS'
export const APP_FILTERS = 'APP_FILTERS'
export const APP_FILTERS_CREATE = 'APP_FILTERS_CREATE'
export const APP_FILTERS_DELETE = 'APP_FILTERS_DELETE'
export const APP_FILTERS_UPDATE = 'APP_FILTERS_UPDATE'
export const AUTH_LOGIN_TENANT = 'AUTH_LOGIN_TENANT'
export const CAMPAIGN_DETAILS = 'CAMPAIGNS_DETAILS'
export const CAMPAIGNS = 'CAMPAIGNS'
export const CAMPAIGNS_PROMOTIONS = 'CAMPAIGNS_PROMOTIONS'
export const CHECK_PROMOTION_AVAILABILITY = 'CHECK_PROMOTION_AVAILABILITY'
export const COLUMN_GROUP_BY_ID = 'COLUMN_GROUP_BY_ID'
export const COLUMN_GROUPS = 'COLUMN_GROUPS'
export const COLUMN_GROUPS_CREATE = 'COLUMN_GROUPS_CREATE'
export const COLUMN_GROUPS_DELETE = 'COLUMN_GROUPS_DELETE'
export const COLUMN_GROUPS_UPDATE = 'COLUMN_GROUPS_UPDATE'
export const CONFIG_ENVIRONMENT_VARIABLES = 'CONFIG_ENVIRONMENT_VARIABLES'
export const CONFIG_SUPPLY_CHAIN_PARAMS = 'CONFIG_SUPPLY_CHAIN_PARAMS'
export const CONFIG_SUPPLY_CHAIN_PARAMS_CREATE = 'CONFIG_SUPPLY_CHAIN_PARAMS_CREATE'
export const CONFIG_SUPPLY_CHAIN_PARAMS_CRITERIA = 'CONFIG_SUPPLY_CHAIN_PARAMS_CRITERIA'
export const CONFIG_SUPPLY_CHAIN_PARAMS_CRITERIA_EXCEL = 'CONFIG_SUPPLY_CHAIN_PARAMS_CRITERIA_EXCEL'
export const DATA_PERMISSIONS_BY_SOURCE = 'DATA_PERMISSIONS_BY_SOURCE'
export const DATA_PERMISSIONS_CREATE = 'DATA_PERMISSIONS_CREATE'
export const DATA_PERMISSIONS_DELETE = 'DATA_PERMISSIONS_DELETE'
export const DATA_PERMISSIONS_UPDATE = 'DATA_PERMISSIONS_UPDATE'
export const DEMAND_FORECASTING = 'DEMAND_FORECASTING'
export const EVENT_CREATE = 'EVENT_CREATE'
export const EVENT_DEFINITIONS = 'EVENTS_DEFINITIONS'
export const EVENT_DELETE = 'EVENT_DELETE'
export const EVENT_DETAILS = 'EVENTS_DETAILS'
export const EVENT_UPDATE = 'EVENT_UPDATE'
export const EVENTS_CALENDAR = 'EVENTS_CALENDAR'
export const PROMOTIONALS_CALENDAR = 'PROMOTIONALS_CALENDAR'
export const FILTER_COLUMNS_BY_DATAMART = 'FILTER_COLUMNS_BY_DATAMART'
export const FILTER_SETS_BY_ID = 'FILTER_SETS_BY_ID'
export const FILTER_VALUES_BY_COLUMN_ID = 'FILTER_VALUES_BY_COLUMN_ID'
export const FILTERED_CAMPAIGNS = 'FILTERED_CAMPAIGNS'
export const FILTERS_FIELD_OPTIONS = 'FILTERS_FIELD_OPTIONS'
export const FILTERS_OPTIONS = 'FILTERS_OPTIONS'
export const FILTERS_SETS = 'FILTERS_SETS'
export const FILTERS_SETS_CREATE = 'FILTERS_SETS_CREATE'
export const FILTERS_SETS_DELETE = 'FILTERS_SETS_DELETE'
export const FILTER_SET_BY_ID = 'FILTER_SET_BY_ID'
export const FORECAST_PERFORMANCE_CHART = 'FORECAST_PERFORMANCE_CHART'
export const GENERATE_EMBED_URL = 'GENERATE_EMBED_URL'
export const INVENTORY_GROUP_COLUMNS = 'INVENTORY_GROUP_COLUMNS'
export const INVENTORY_METRICS = 'INVENTORY_METRICS'
export const INVENTORY_WAREHOUSES = 'INVENTORY_WAREHOUSES'
export const INVITED_SUPPLIERS_LIST = 'INVITED_SUPPLIERS_LIST'
export const KNOWLEDGE_BASE_PAGE = 'KNOWLEDGE_BASE_PAGE'
export const METABASE_DASHBOARD = 'METABASE_DASHBOARD'
export const PERMISSIONS_LIST = 'PERMISSIONS_LIST'
export const PROCUREMENT_CALENDAR_DAYS = 'PROCUREMENT_CALENDAR_DAYS'
export const PROCUREMENT_CALENDAR_ITEMS = 'PROCUREMENT_CALENDAR_ITEMS'
export const PROCUREMENT_LIST = 'PROCUREMENT_LIST'
export const PROCUREMENT_LIST_EXCEL = 'PROCUREMENT_LIST_EXCEL'
export const PROCUREMENT_LIST_GROUP_COLUMNS = 'PROCUREMENT_LIST_GROUP_COLUMNS'
export const PROCUREMENT_ORDER_CREATE = 'PROCUREMENT_ORDER_CREATE'
export const PROCUREMENT_ORDER_CREATE_DRAFT = 'PROCUREMENT_ORDER_CREATE_DRAFT'
export const PROCUREMENT_ORDER_RESTRICTIONS = 'PROCUREMENT_ORDER_RESTRICTIONS'
export const PRODUCT = 'PRODUCT'
export const PRODUCT_LIST_WITH_FORECAST_EXCEL = 'PRODUCT_LIST_WITH_FORECAST_EXCEL'
export const PRODUCTS_FORECAST_DETAILS = 'PRODUCTS_FORECAST_DETAILS'
export const PROMOTION = 'PROMOTION'
export const PROMOTION_ESTIMATION = 'PROMOTION_ESTIMATION'
export const PROMOTION_PRODUCTS = 'PROMOTION_PRODUCTS'
export const QUERY_BUILDER_DATASET_COLUMNS = 'QUERY_BUILDER_DATASET_COLUMNS'
export const REPLENISHMENT_LIST = 'REPLENISHMENT_LIST'
export const REPLENISHMENT_LIST_EXCEL = 'REPLENISHMENT_LIST_EXCEL'
export const REPLENISHMENT_LIST_GROUP_COLUMNS = 'REPLENISHMENT_LIST_GROUP_COLUMNS'
export const REPLENISHMENT_ORDER_CREATE = 'REPLENISHMENT_ORDER_CREATE'
export const REPLENISHMENT_ORDER_CREATE_DRAFT = 'REPLENISHMENT_ORDER_CREATE_DRAFT'
export const REPLENISHMENT_ORDER_RESTRICTIONS = 'REPLENISHMENT_ORDER_RESTRICTIONS'
export const REPLENISHMENT_PRODUCT = 'REPLENISHMENT_PRODUCT'
export const REPLENISHMENT_SUGGESTIONS = 'REPLENISHMENT_SUGGESTIONS'
export const REPLENISHMENT_SUGGESTIONS_EXCEL = 'REPLENISHMENT_SUGGESTIONS_EXCEL'
export const REPLENISHMENT_SUGGESTIONS_ORDER_CREATE = 'REPLENISHMENT_SUGGESTIONS_ORDER_CREATE'
export const ROLES_LIST = 'ROLES_LIST'
export const SCENARIO = 'SCENARIO'
export const SCENARIO_PRODUCTS = 'SCENARIO_PRODUCTS'
export const SCENARIOS = 'SCENARIOS'
export const SELLTHROUGHRATE = 'SELLTHROUGHRATE'
export const TENANTS_CREATE = 'TENANTS_CREATE'
export const TENANTS_LIST = 'TENANTS_LIST'
export const TENANTS_MODULES = 'TENANTS_MODULES'
export const TENANTS_PERMISSIONS = 'TENANTS_PERMISSIONS'
export const USER_ASIGN_ROLES = 'USER_ASIGN_ROLES'
export const USER_BY_GLOBAL_ID = 'USER_BY_GLOBAL_ID'
export const USER_DATA_PERMISSIONS = 'USER_DATA_PERMISSIONS'
export const USER_DETAIL = 'USER_DETAIL'
export const USER_WAREHOUSES = 'USER_WAREHOUSES'
export const USERS_LIST = 'USERS_LIST'
export const USERS_ROLES = 'USERS_ROLES'
export const WAREHOUSES_BY_USER_ID = 'WAREHOUSES_BY_USER_ID'
export const WAREHOUSES_BY_USER_ID_ALLOCATE = 'WAREHOUSES_BY_USER_ID_ALLOCATE'
export const WAREHOUSES_LIST = 'WAREHOUSES_LIST'
