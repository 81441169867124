import { useMutation } from '@tanstack/react-query'
import { api } from '~/lib/api'
import { FILTER_SET_BY_ID } from '~/lib/queryKeys'
import type { ApiResponse } from '~/types/apiContracts'

///TODO: The operator attribute should be mapped,
// but there is currently an inconsistency in the backend that needs to be fixed regarding the values “is null” and “is not null”,
// which are now “is empty” and “is not empty.” This will be solve in the future.
interface FilterSetById {
  filter_set: {
    app_name: string
    datamart: string
    id: number
    name: string
    source: string
    user: unknown
    filters: {
      column: {
        data_type: string
        datamart: string
        field: string
        format: string
        id: number
        label: string
      }
      datamart: string
      id: number
      operator:
        | 'is'
        | 'is not'
        | 'not in'
        | 'in'
        | 'greater than'
        | 'greaterOrEqual'
        | 'less than'
        | 'lessOrEqual'
        | 'between'
        | 'is empty'
        | 'is not empty'[]
      value: string[]
    }[]
  }
}

const fetchFilterSetById = async (filterSetId: number) => {
  const res = (await api
    .get(`configs/query-builder/filter-sets/${filterSetId}`)
    .json()) as ApiResponse<FilterSetById>
  return res.data
}

export const useFilterSetById = () => {
  return useMutation({
    mutationKey: [FILTER_SET_BY_ID],
    mutationFn: (filterSetId: number) => {
      return fetchFilterSetById(filterSetId)
    },
  })
}
