import { useFlags } from 'launchdarkly-react-client-sdk'
import type { ReactNode } from 'react'

type FeatureProps = {
  flag: string
  fallback?: React.ReactNode
  children: (() => ReactNode) | ReactNode
}

export const Feature = ({ flag, fallback = null, children }: FeatureProps) => {
  const flags = useFlags()

  const isFlagEnabled = !!flags[flag]

  if (!isFlagEnabled) {
    return fallback
  }

  if (typeof children === 'function') {
    return children()
  }

  return children
}
