export async function enableMockingWithMSW() {
  if (import.meta.env.VITE_ENABLE_MOCK_API !== 'true') {
    return
  }

  const { worker } = await import('../mocks/browser')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({
    onUnhandledRequest: 'bypass',
  })
}
