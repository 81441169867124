import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from '~/components/UI/Toast'
import { api } from '~/lib/api'
import { queryClient } from '~/lib/queryClient'
import { FILTERS_SETS, FILTERS_SETS_DELETE } from '~/lib/queryKeys'
import type { ApiResponse } from '~/types/apiContracts'

interface FilterSetDeleteArgs {
  moduleId: string
  filtersSetName: string
  filterSetId: number | undefined
}

const deleteFiltersSet = async (filterSetId: number | undefined) => {
  const res = (await api
    .delete(`configs/query-builder/filter-sets/${filterSetId}`)
    .json()) as ApiResponse<unknown>

  return res.data
}

export const useFiltersSetDeleteMutation = () => {
  const { t } = useTranslation(['filter'])

  return useMutation({
    mutationKey: [FILTERS_SETS_DELETE],
    mutationFn: ({ filterSetId }: FilterSetDeleteArgs) => {
      return deleteFiltersSet(filterSetId)
    },
    onSuccess: (_data, variables) => {
      toast({
        status: 'success',
        content: t('filter:status.successDelete', { filterName: variables?.filtersSetName }),
      })
      queryClient.invalidateQueries({ queryKey: [FILTERS_SETS, variables.moduleId] })
    },
  })
}
