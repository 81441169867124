import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import { initBugsnag } from './lib/bugsnag'
import { initLaunchDarkly } from './lib/launchDarkly'
import { enableMockingWithMSW } from './lib/msw'

// eslint-disable-next-line react-refresh/only-export-components
const ErrorBoundary = initBugsnag()

// eslint-disable-next-line react-refresh/only-export-components
const LDProvider = await initLaunchDarkly()

await enableMockingWithMSW()

const rootElement = document.getElementById('app')!

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)

  let app = <App />

  if (ErrorBoundary) {
    app = <ErrorBoundary>{app}</ErrorBoundary>
  }

  if (LDProvider) {
    app = <LDProvider>{app}</LDProvider>
  }

  root.render(<StrictMode>{app}</StrictMode>)
}
