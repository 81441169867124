import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'

export async function initLaunchDarkly() {
  // TODO: check later how we can easily disable this in development
  // if (import.meta.env.DEV) return

  return asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCHDARKLY_KEY,
    context: {
      kind: 'env',
      key: __DEPLOY_ENV__,
      name: __DEPLOY_ENV__,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  })
}
