import { useQuery } from '@tanstack/react-query'
import { api } from '~/lib/api'
import { FILTERS_OPTIONS } from '~/lib/queryKeys'
import type { ApiResponse, FilterOption } from '~/types/apiContracts'

const getFiltersOptions = async (moduleId: string) => {
  const res = (await api
    .get(`configs/query-builder/columns/apps/${moduleId}`)
    .json()) as ApiResponse<FilterOption[]>
  return res.data
}

export const useFiltersOptionsQuery = (moduleId: string) => {
  return useQuery({
    queryKey: [FILTERS_OPTIONS, moduleId],
    queryFn: () => getFiltersOptions(moduleId),
    enabled: !!moduleId,
  })
}
