import clsx from 'clsx/lite'
import type { PropsWithChildren } from 'react'
import type { OptionalClassName } from '~/types'

export type DrawerOptionsProps = OptionalClassName & PropsWithChildren

export const DrawerOptions = ({ className, children }: DrawerOptionsProps) => {
  return (
    <div
      className={clsx(
        'sticky bottom-0 w-full border-t border-t-neutral-200 bg-white/50 backdrop-blur-md',
        className,
      )}
    >
      {children}
    </div>
  )
}
