import { Input as BaseInput } from '@mui/base/Input'
import { clsx } from 'clsx'
import React from 'react'
import type { InputProps } from './types'

export const Input = React.forwardRef(function Input(
  props: InputProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { variant = 'outlined', size = 'medium', slotProps, ...other } = props

  return (
    <BaseInput
      slotProps={{
        root: (ownerState) => {
          return {
            className: clsx(
              'relative flex min-w-32 items-center overflow-hidden bg-white text-night-800 outline-night-100 transition-all duration-75 hover:border-night-100 hover:bg-seasalt',
              variant === 'outlined' && [
                'rounded-xl border border-night-200 bg-white hover:outline hover:outline-1 has-[:disabled]:border-none has-[:focus]:border-azure has-[:disabled]:bg-night-50 has-[:disabled]:text-night-200 has-[:disabled]:outline-none has-[:focus]:outline has-[:focus]:outline-1 has-[:focus]:outline-azure',
                ownerState.error && '!border-bittersweet outline outline-1 !outline-bittersweet',
              ],
              variant === 'filled' && [
                'border-b border-night-200 hover:border-b-2 hover:border-night-100 has-[:disabled]:border-b-0 has-[:focus]:border-b-2 has-[:focus]:border-azure has-[:disabled]:bg-night-50 has-[:disabled]:text-night-200',
                ownerState.error && 'border-b-2 !border-bittersweet',
              ],
              (!ownerState.multiline || (ownerState.multiline && !ownerState.rows)) && [
                size === 'small' && 'h-7',
                size === 'medium' && 'h-9',
                size === 'large' && 'h-11',
              ],
            ),
            ...slotProps?.root,
          }
        },
        input: (ownerState) => ({
          ...slotProps?.input,
          className: clsx(
            'text-body-1 max-w-full flex-grow bg-transparent caret-azure outline-none transition-all duration-200 placeholder:text-night-400 disabled:placeholder:text-night-200',
            size === 'small' && 'text-body-2 px-3',
            size === 'medium' && 'px-4',
            size === 'large' && 'px-4',
            ownerState.multiline
              ? ['resize-none content-start self-stretch', ownerState.rows ? 'pt-2' : 'pt-1']
              : 'content-center truncate',
            props.endAdornment && 'pr-10', // TODO: should be different for each size
            props.startAdornment && 'pl-10', // TODO: should be different for each size
            props.inputAlignment === 'left' && 'text-left',
            props.inputAlignment === 'center' && 'text-center',
            props.inputAlignment === 'right' && 'text-right',
            ownerState.focused && 'outline-none',
            props.type === 'number' && 'text-right',
          ),
        }),
      }}
      {...other}
      startAdornment={
        props.startAdornment ? (
          <span className="absolute left-3 me-1 lg:me-3">{props.startAdornment}</span>
        ) : undefined
      }
      endAdornment={
        props.endAdornment ? (
          <span className="absolute right-3 me-1 lg:me-3">{props.endAdornment}</span>
        ) : undefined
      }
      ref={ref}
    />
  )
})
