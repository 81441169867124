import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useFormikContext } from 'formik'
import { api, stringifyQueryParams } from '~/lib/api'
import { FILTERS_FIELD_OPTIONS } from '~/lib/queryKeys'
import type { Filter, FilterShort } from '~/providers/FiltersProvider'
import type { ApiResponse, FilterFieldOption } from '~/types/apiContracts'

interface FilterFieldOptionResponse {
  options: FilterFieldOption[]
}

interface FilterFieldOptions {
  moduleId: string
  fieldName: string
  search: string
  filters: FilterShort[]
}

export interface UseFilterFieldOptionQueryOptions {
  moduleId: string
  fieldName: string
  search: string
  enabled: boolean
}

export const getFiltersFieldOptions = async ({
  fieldName,
  filters,
  moduleId,
  search,
}: FilterFieldOptions) => {
  const searchParams = stringifyQueryParams({
    page_size: 25,
    page: 0,
    search,
  })

  const res = (await api
    .post(`filters/${moduleId}/get-options/${fieldName}`, {
      searchParams,
      json: { filters },
    })
    .json()) as ApiResponse<FilterFieldOptionResponse>
  return res.data
}

export const useFilterFieldOptionsQuery = ({
  fieldName,
  moduleId,
  search,
  enabled,
}: UseFilterFieldOptionQueryOptions) => {
  const { values } = useFormikContext<{ filters: Filter[] }>()

  const formFilters = values.filters.filter((filter) =>
    [filter?.field, filter?.operator, (filter?.value as { value: string }[])?.length > 0].every(
      Boolean,
    ),
  )

  const formattedFilters = formFilters
    .filter((filter) => filter.field !== fieldName)
    .map((filter) => {
      return {
        field: filter.field,
        operator: filter.operator,
        value: (filter.value as { value: string }[]).map(({ value }) => value),
      }
    })

  return useQuery({
    queryKey: [FILTERS_FIELD_OPTIONS, moduleId, fieldName, search, formattedFilters],
    queryFn: () =>
      getFiltersFieldOptions({ moduleId, fieldName, search, filters: formattedFilters }),
    staleTime: 5 * 60 * 1000,
    enabled,
    placeholderData: search ? keepPreviousData : undefined,
  })
}
