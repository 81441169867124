import {
  type GridColDef,
  type GridRowClassNameParams,
  type GridValidRowModel,
} from '@mui/x-data-grid-premium'
import { useSearch } from '@tanstack/react-router'
import { z } from 'zod'
import { StockStatusChip } from '~/components/StockStatusChip'
import { editableCellProps } from '~/components/UI/CustomEditableCell/editableCellProps'
import { getSuggestedInputQuantity } from '~/lib/core'
import type { CalculatedColumnParsedSerializedFunction } from '~/lib/core/calculatedColumns'
import { checkIfColumnIsCalculated, parseSerializedFunction } from '~/lib/core/calculatedColumns'
import i18n from '~/lib/i18n'
import type {
  ApiColDef,
  StockStatus,
  WithInputQuantity,
  WithRequestedQuantity,
} from '~/types/apiContracts'
import type { GetColumnsBaseParams } from '~/types/shared'
import { processApiColumns, wrapActionsColDef } from '~/utils/dataGrid'

export const inventoryOrderSearch = z.object({
  orderDetails: z.record(z.string(), z.any()),
  keyColumnNames: z.array(z.string()),
  keyVisibleColumnLabelsMap: z.record(z.string(), z.string()),
  keyDate: z.string().date().optional(),
})

export type InventoryOrderSearch = z.infer<typeof inventoryOrderSearch>

export function useInventoryOrderSearchParams() {
  return useSearch({
    strict: false,
  }) as InventoryOrderSearch
}

export const inputQuantityFieldName: keyof WithInputQuantity = 'input_quantity' as const

export function checkIfIsStockStatusColumn(columnField: string) {
  return columnField === 'inventory_stock' || columnField === 'inventory_stock_dw'
}

export function extendStockStatusColumns<T extends WithInputQuantity>(
  columns: GridColDef<T>[],
): GridColDef<T>[] {
  return columns.map((column) => {
    if (checkIfIsStockStatusColumn(column.field)) {
      return {
        ...column,
        renderCell: (params) => {
          return <StockStatusChip stockStatusCode={params.value as StockStatus} />
        },
      }
    }

    return column
  })
}

export interface GetProductsTableColumnsCommonParams<
  R extends GridValidRowModel = GridValidRowModel,
> extends GetColumnsBaseParams<R> {
  includeInputQuantityColumn?: boolean
}

export function getProductsTableCommonColumns<T extends WithInputQuantity>({
  apiColumns,
  getActions,
  includeInputQuantityColumn = true,
}: GetProductsTableColumnsCommonParams<T>): GridColDef<T>[] {
  let columns: GridColDef<T>[] = processApiColumns(apiColumns, {
    defaultColDefProps: { minWidth: 150, flex: 1 },
  })

  columns = extendStockStatusColumns(columns)

  columns.push(wrapActionsColDef({ getActions, minWidth: 100 }))

  const inputQuantityColumnIdx = columns.findIndex(
    (column) => column.field === inputQuantityFieldName,
  )

  if (inputQuantityColumnIdx !== -1) {
    if (includeInputQuantityColumn) {
      columns[inputQuantityColumnIdx] = {
        ...columns[inputQuantityColumnIdx],
        headerName: i18n.t('work-area:order.input_quantity'),
        editable: true,
        valueGetter: (value) => value ?? null,
        hideable: false,
        minWidth: 100,
        ...editableCellProps,
      }
    } else {
      // remove it from the columns
      columns.splice(inputQuantityColumnIdx, 1)
    }
  }

  return columns
}

export const getProductListRowClassNameFn = (columnWithSuggestedInputValue?: ApiColDef) => {
  if (!columnWithSuggestedInputValue) {
    return undefined
  }

  let parsedSerializedFunction: CalculatedColumnParsedSerializedFunction | undefined

  if (checkIfColumnIsCalculated(columnWithSuggestedInputValue)) {
    // we get the parsed function here to avoid parsing it multiple times for each item
    try {
      parsedSerializedFunction = parseSerializedFunction(columnWithSuggestedInputValue.formula)
    } catch (error) {
      console.error(error)
    }
  }

  const getRowClassName = (param: GridRowClassNameParams<WithRequestedQuantity>): string => {
    const row = param.row
    const quantityRequested = row.quantity_requested_with_celes ?? 0

    if (!quantityRequested) {
      return ''
    }

    const suggestedInputQuantity = getSuggestedInputQuantity({
      item: row,
      columnWithSuggestedInputValue: columnWithSuggestedInputValue,
      parsedSerializedFunction,
    })

    if (quantityRequested >= suggestedInputQuantity) {
      return 'bg-emerald-50'
    }

    return 'bg-orangepeel-50'
  }

  return getRowClassName
}
